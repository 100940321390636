<template>
  <AppLoader v-if="isLoading || loader"></AppLoader>
  <div v-if="!loader" class="min-h-100vh min-h-inherit-sm">
    <div class="bg-blue">
      <div class="topbar p-30 w-1400 ms-auto me-auto">
        <a class="logo" href="">
          <svg width="202" height="36" viewBox="0 0 202 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.1241 22.0188H23.0091C23.5982 22.0188 23.9516 21.5475 23.9516 21.0763V19.1913C23.9516 18.6022 23.4804 18.2488 23.0091 18.2488H21.1241C20.5351 18.2488 20.1816 18.72 20.1816 19.1913V21.0763C20.1816 21.5475 20.5351 22.0188 21.1241 22.0188Z"
              fill="white" />
            <path
              d="M14.9972 22.0188H16.8822C17.4712 22.0188 17.8247 21.5475 17.8247 21.0763V19.1913C17.8247 18.6022 17.3534 18.2488 16.8822 18.2488H14.9972C14.4081 18.2488 14.0547 18.72 14.0547 19.1913V21.0763C14.0547 21.5475 14.4081 22.0188 14.9972 22.0188Z"
              fill="white" />
            <path
              d="M20.1816 27.0833C20.1816 27.6724 20.6529 28.0258 21.1241 28.0258H23.0091C23.5982 28.0258 23.9516 27.5546 23.9516 27.0833V25.1984C23.9516 24.6093 23.4804 24.2559 23.0091 24.2559H21.1241C20.5351 24.2559 20.1816 24.7271 20.1816 25.1984V27.0833Z"
              fill="white" />
            <path
              d="M14.0547 27.0833C14.0547 27.6724 14.526 28.0258 14.9972 28.0258H16.8822C17.4712 28.0258 17.8247 27.5546 17.8247 27.0833V25.1984C17.8247 24.6093 17.3534 24.2559 16.8822 24.2559H14.9972C14.4081 24.2559 14.0547 24.7271 14.0547 25.1984V27.0833Z"
              fill="white" />
            <path
              d="M37.7041 16.2923L19.9307 0.380859C19.3382 -0.126953 18.5765 -0.126953 17.9841 0.380859L12.652 5.20508V3.597C12.652 3.08919 12.2289 2.66602 11.721 2.66602H9.94369C9.43588 2.66602 9.0127 3.08919 9.0127 3.597V8.50585L0.295266 16.2923C-0.212546 16.8001 -0.0432804 17.1387 0.633803 17.1387H2.32651C3.00359 17.1387 3.59604 17.7311 3.59604 18.4082V34.6582C3.59604 35.3353 4.18849 35.9277 4.86557 35.9277H7.5739V22.7246C7.5739 16.3769 12.7367 11.2988 18.9997 11.2988C19.846 11.2988 20.6924 11.3835 21.5387 11.5527C26.7861 12.7376 30.4255 17.3926 30.4255 22.7246C30.4255 29.0723 25.2627 34.1504 18.9997 34.1504C17.4762 34.1504 15.9528 33.8118 14.514 33.2194L10.2822 35.9277H33.1338C33.8109 35.9277 34.4033 35.3353 34.4033 34.6582V18.4082C34.4033 17.7311 34.9958 17.1387 35.6728 17.1387H37.3656C38.0426 17.1387 38.2119 16.7155 37.7041 16.2923Z"
              fill="white" />
            <path
              d="M67.7795 30.795C65.3488 30.795 63.2453 30.2691 61.469 29.2174C59.7161 28.1422 58.3605 26.6581 57.4022 24.765C56.444 22.8484 55.9648 20.6398 55.9648 18.1389C55.9648 15.6147 56.444 13.4061 57.4022 11.5129C58.3605 9.59641 59.7161 8.10059 61.469 7.02547C63.2453 5.95035 65.3488 5.41279 67.7795 5.41279C70.6543 5.41279 73.0032 6.11396 74.8262 7.51629C76.6492 8.91862 77.8062 10.8819 78.297 13.4061H74.1601C73.8329 11.9804 73.1317 10.8468 72.0566 10.0054C70.9815 9.16402 69.5441 8.74333 67.7444 8.74333C65.2903 8.74333 63.3505 9.58473 61.9248 11.2675C60.499 12.9269 59.7862 15.2174 59.7862 18.1389C59.7862 21.0371 60.499 23.3159 61.9248 24.9753C63.3505 26.6347 65.2903 27.4644 67.7444 27.4644C69.5441 27.4644 70.9815 27.0671 72.0566 26.2725C73.1317 25.4778 73.8329 24.391 74.1601 23.012H78.297C77.8062 25.4428 76.6492 27.3476 74.8262 28.7265C73.0032 30.1055 70.6543 30.795 67.7795 30.795Z"
              fill="white" />
            <path d="M84.5367 30.3743V5.13232H88.2529V30.3743H84.5367Z" fill="white" />
            <path
              d="M101.67 30.795C99.5662 30.795 97.9068 30.1406 96.6914 28.8317C95.4995 27.5229 94.9035 25.5713 94.9035 22.977V12.9854H98.6196V22.5913C98.6196 25.9569 99.9986 27.6397 102.757 27.6397C104.135 27.6397 105.269 27.1489 106.157 26.1673C107.045 25.1857 107.489 23.7833 107.489 21.9603V12.9854H111.206V30.3743H107.91L107.63 27.3242C107.092 28.3993 106.297 29.2524 105.246 29.8835C104.217 30.4911 103.025 30.795 101.67 30.795Z"
              fill="white" />
            <path
              d="M128.009 30.795C126.654 30.795 125.45 30.5262 124.398 29.9886C123.37 29.4511 122.552 28.6915 121.944 27.7099L121.559 30.3743H118.228V5.13232H121.944V15.6849C122.505 14.8668 123.276 14.1423 124.258 13.5113C125.24 12.8802 126.502 12.5647 128.044 12.5647C129.727 12.5647 131.211 12.962 132.497 13.7567C133.782 14.5513 134.787 15.6381 135.512 17.0171C136.26 18.396 136.634 19.962 136.634 21.7149C136.634 23.4678 136.26 25.0337 135.512 26.4127C134.787 27.7683 133.782 28.8434 132.497 29.6381C131.211 30.4093 129.716 30.795 128.009 30.795ZM127.378 27.5696C128.968 27.5696 130.276 27.0321 131.305 25.9569C132.333 24.8584 132.847 23.4327 132.847 21.6798C132.847 20.5346 132.614 19.5179 132.146 18.6298C131.679 17.7416 131.036 17.0521 130.218 16.5613C129.4 16.0471 128.453 15.79 127.378 15.79C125.789 15.79 124.48 16.3393 123.452 17.4378C122.447 18.5363 121.944 19.9503 121.944 21.6798C121.944 23.4327 122.447 24.8584 123.452 25.9569C124.48 27.0321 125.789 27.5696 127.378 27.5696Z"
              fill="white" />
            <path
              d="M151.082 30.795C149.376 30.795 147.856 30.421 146.524 29.6731C145.215 28.9018 144.187 27.8384 143.439 26.4828C142.691 25.1272 142.317 23.5496 142.317 21.7499C142.317 19.9269 142.679 18.3259 143.404 16.947C144.152 15.568 145.18 14.4929 146.489 13.7216C147.821 12.9503 149.364 12.5647 151.117 12.5647C152.823 12.5647 154.307 12.9503 155.569 13.7216C156.831 14.4695 157.813 15.4745 158.514 16.7366C159.215 17.9987 159.566 19.3894 159.566 20.9085C159.566 21.1423 159.554 21.3994 159.531 21.6798C159.531 21.9369 159.519 22.2291 159.496 22.5563H145.963C146.08 24.2391 146.629 25.5246 147.611 26.4127C148.616 27.2775 149.773 27.7099 151.082 27.7099C152.133 27.7099 153.01 27.4761 153.711 27.0087C154.436 26.5179 154.973 25.8635 155.324 25.0454H159.04C158.573 26.6815 157.638 28.0487 156.235 29.1472C154.856 30.2457 153.138 30.795 151.082 30.795ZM151.082 15.6147C149.843 15.6147 148.745 15.9887 147.786 16.7366C146.828 17.4612 146.244 18.5596 146.033 20.0321H155.85C155.78 18.6765 155.3 17.6014 154.412 16.8067C153.524 16.0121 152.414 15.6147 151.082 15.6147Z"
              fill="white" />
            <path
              d="M173.85 30.795C172.144 30.795 170.625 30.421 169.293 29.6731C167.984 28.9018 166.956 27.8384 166.208 26.4828C165.46 25.1272 165.086 23.5496 165.086 21.7499C165.086 19.9269 165.448 18.3259 166.173 16.947C166.921 15.568 167.949 14.4929 169.258 13.7216C170.59 12.9503 172.132 12.5647 173.885 12.5647C175.592 12.5647 177.076 12.9503 178.338 13.7216C179.6 14.4695 180.582 15.4745 181.283 16.7366C181.984 17.9987 182.334 19.3894 182.334 20.9085C182.334 21.1423 182.323 21.3994 182.299 21.6798C182.299 21.9369 182.288 22.2291 182.264 22.5563H168.732C168.849 24.2391 169.398 25.5246 170.38 26.4127C171.385 27.2775 172.542 27.7099 173.85 27.7099C174.902 27.7099 175.779 27.4761 176.48 27.0087C177.204 26.5179 177.742 25.8635 178.092 25.0454H181.809C181.341 26.6815 180.406 28.0487 179.004 29.1472C177.625 30.2457 175.907 30.795 173.85 30.795ZM173.85 15.6147C172.612 15.6147 171.513 15.9887 170.555 16.7366C169.597 17.4612 169.012 18.5596 168.802 20.0321H178.618C178.548 18.6765 178.069 17.6014 177.181 16.8067C176.293 16.0121 175.183 15.6147 173.85 15.6147Z"
              fill="white" />
            <path
              d="M187.329 30.3743V27.3593L196.619 16.0705H187.469V12.9854H200.826V16.0004L191.465 27.2892H201.001V30.3743H187.329Z"
              fill="white" />
          </svg>
        </a>
      </div>
      <div class="top-title pt-57 pb-235 w-1400 ms-auto me-auto">
        <h2 class="font-61 font-40-sm font-30-vsm text-capitalize font-w-600 text-white text-center">
          {{ subscriberInfo.clubName }}
        </h2>
      </div>
    </div>
    <div class="ms-2 me-2">
      <div v-if="modalStepper == 1"
        class="p-60 p-10-sm box-shadow-4 radius-15 ms-auto me-auto w-833 min-h-575 mt--175 mb-175 bg-white text-center">
        <h3 class="font-44 font-34-sm font-26-vsm l-height-51 font-w-600 mb-60 mb-15-sm">Unsubscribe</h3>
        <p class="font-24 font-16-sm font-14-vsm l-height-29 l-height-26-sm">Your Email Address <b class="word-break-all">{{
          subscriberInfo.subscriberEmail }}</b>
          <br /><br />
          You will not Receive any more emails from
          <b class="d-block w-100 word-break-all">{{ subscriberInfo.senderEmail }}</b>
        </p>
        <h3 class="font-32 font-18-sm l-height-58 font-w-600 mb-24">Do you want to unsubscribe?</h3>
        <div>
          <kbutton @click="updateSubscriberInfo()" :theme-color="'primary'"
            :class="'font-16 font-w-600 radius-50 px-47 py-11 mb-32'">Unsubscribe</kbutton>
        </div>
        <div>
          <kbutton @click="cancel()" :class="'btn-clear p-0'">Cancel</kbutton>
        </div>
      </div>
      <div v-if="modalStepper == 2"
        class="p-60 p-10-sm box-shadow-4 radius-15 ms-auto me-auto w-833 min-h-575 mt--175 mb-175 bg-white text-center">
        <h3 class="font-44 font-34-sm font-26-vsm l-height-51 font-w-600 mb-60 mb-15-sm">Unsubscribed</h3>
        <p class="font-24 font-16-sm font-14-vsm l-height-29 l-height-26-sm mb-56">You have been successfully removed from this
          subscriber list and won't
          receive any further emails from us.</p>
        <h3 class="font-32 font-22-sm l-height-58 l-height-26-sm font-w-600 mb-24">Did you unsubscribe by accident?</h3>
        <kbutton @click="updateSubscriberInfo()" :theme-color="'primary'"
          :class="'font-16 font-w-600 radius-50 px-47 py-11'">Re-Subscribe</kbutton>
      </div>
      <div v-if="modalStepper == 3"
        class="p-60 box-shadow-4 radius-15 ms-auto me-auto w-833 mt--175 mb-175 bg-white text-center d-flex flex-column justify-content-center min-h-575">
        <div class="mb-44">
          <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2_500)">
              <path
                d="M37 74C57.4367 74 74 57.4367 74 37C74 16.5633 57.4367 0 37 0C16.5633 0 0 16.5633 0 37C0 57.4367 16.5633 74 37 74ZM53.332 30.207L34.832 48.707C33.4734 50.0656 31.2766 50.0656 29.9324 48.707L20.6824 39.457C19.3238 38.0984 19.3238 35.9016 20.6824 34.5574C22.041 33.2133 24.2379 33.1988 25.582 34.5574L32.375 41.3504L48.418 25.293C49.7766 23.9344 51.9734 23.9344 53.3176 25.293C54.6617 26.6516 54.6762 28.8484 53.3176 30.1926L53.332 30.207Z"
                fill="#002F75" />
            </g>
            <defs>
              <clipPath id="clip0_2_500">
                <rect width="74" height="74" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <h1 class="font-45 l-height-51 font-w-600 mb-24">Thank You</h1>
        <p class="font-24 font-16-sm font-14-vsm l-height-29 l-height-26-sm mb-0">Your subscription has now been successfully
          confirmed.</p>
      </div>
    </div>

    <div class="unsubscribe-footer bg-dard-blue color-white px-3">
      <div class="unsubscribe-footer-top max-w-1400 ms-auto me-auto border-bottom-transparent-white py-56">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <h2 class="font-41 font-34-sm l-height-48 text-white mb-8">Ready to get started?</h2>
          </div>
          <div class="col-md-6 col-sm-12 text-left text-sm-start text-md-end">
            <kbutton :theme-color="'primary'" :class="'font-16 font-w-600 radius-50 px-25 py-11 mb-8'">Request a Demo
            </kbutton>
          </div>
        </div>
      </div>
      <div class="unsubscribe-footer-middle max-w-1400 ms-auto me-auto border-bottom-transparent-white py-56">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12">
            <div class="unsubscribe-footer-col mb-10">
              <div class="mb-17">
                <a href="#">
                  <img src="../../../src/assets/images/logo-white.png" alt="">
                </a>
              </div>
              <p class="font-15 l-height-24 text-white mb-20">Membership pass, loyalty pass, boarding pass, retail store
                payment cards- Digital Wallet passes solutions.</p>
              <ul class="p-0 d-flex">
                <li class="mr-30">
                  <a href="#" class="d-inline-block"><img src="../../../src/assets/images/social/facebook.png" alt=""></a>
                </li>
                <li class="mr-30">
                  <a href="#" class="d-inline-block"><img src="../../../src/assets/images/social/twiter.png" alt=""></a>
                </li>
                <li class="mr-30">
                  <a href="#" class="d-inline-block"><img src="../../../src/assets/images/social/linkedin.png" alt=""></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <h4 class="font-18 l-height-22 text-white mb-22">Quick links</h4>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <ul class="p-0 d-block">
                  <li class="d-block mb-19"><a href="#" class="d-inline-block text-white">Home</a></li>
                  <li class="d-block mb-19"><a href="#" class="d-inline-block text-white">About</a></li>
                  <li class="d-block mb-19"><a href="#" class="d-inline-block text-white">Pricing</a></li>
                  <li class="d-block mb-19"><a href="#" class="d-inline-block text-white">Blog</a></li>
                </ul>
              </div>
              <div class="col-md-6 col-sm-12">
                <ul class="p-0 d-block">
                  <li class="d-block mb-19"><a href="#" class="d-inline-block text-white">Terms & Conditions</a></li>
                  <li class="d-block mb-19"><a href="#" class="d-inline-block text-white">Privacy Policy</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <h4 class="font-18 l-height-22 text-white mb-22">Contact</h4>
            <ul class="p-0 d-block">
              <li class="d-block mb-26">
                <a href="mailto:team@clubeez.com" class="font-19 l-height-23 d-inline-block text-white">
                  <img src="../../../src/assets/images/social/mail.png" alt="" class="mr-15">
                  team@clubeez.com
                </a>
              </li>
              <li class="d-block mb-26">
                <a href="tel:+61 3 8669 0640" class="font-19 l-height-23 d-inline-block text-white">
                  <img src="../../../src/assets/images/social/mobile.png" alt="" class="mr-15">
                  +61 3 8669 0640
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="unsubscribe-footer-bottom max-w-1400 ms-auto me-auto py-28">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <p class="font-16 l-height-19 text-white mb-8 opacity-8">Copyright © 2022 Clubeez - All Rights Reserved</p>
          </div>
          <div class="col-md-6 col-sm-12 text-left text-sm-start text-md-end">
            <kbutton @click="scrollToTopHandler" :fill-mode="'flat'" :class="'p-0 mb-8'">
              <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.8"
                  d="M13.2471 7.70905C13.0759 7.88061 12.8439 7.9776 12.6015 7.97891C12.3592 7.98022 12.1261 7.88575 11.9531 7.71605L7.67108 3.44805V19.7111C7.67108 19.9535 7.57479 20.1859 7.40338 20.3573C7.23197 20.5288 6.99949 20.6251 6.75708 20.6251C6.51468 20.6251 6.2822 20.5288 6.11079 20.3573C5.93938 20.1859 5.84309 19.9535 5.84309 19.7111V3.44805L1.55709 7.72305C1.38331 7.8913 1.15054 7.98479 0.908657 7.98349C0.666777 7.98218 0.435032 7.88618 0.263085 7.71605C0.179031 7.63109 0.112537 7.5304 0.0673991 7.41974C0.0222611 7.30908 -0.000636561 7.19061 1.34611e-05 7.0711C0.000663483 6.95159 0.024849 6.83338 0.0711881 6.72322C0.117527 6.61305 0.185112 6.51309 0.270085 6.42905L6.10609 0.635053C6.19067 0.555916 6.28805 0.491674 6.39408 0.445053C6.50529 0.397565 6.62517 0.373726 6.74609 0.375052C6.98518 0.374873 7.21486 0.468182 7.38608 0.635053L13.2221 6.42905C13.3084 6.51109 13.3776 6.60944 13.4256 6.7184C13.4736 6.82736 13.4996 6.94478 13.5019 7.06383C13.5042 7.18289 13.4829 7.30122 13.4391 7.41198C13.3954 7.52273 13.3301 7.62371 13.2471 7.70905Z"
                  fill="white" />
              </svg>
            </kbutton>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { configuration } from "@/configurationProvider"; import axios from "axios";
import AppLoader from "../common/AppLoader/AppLoader.vue";
export default {
  name: "subscribeUnsubscribeECModal",
  components: {
    kbutton: Button,
    AppLoader,
  },
  props: {
    size: {
      type: String,
      default: "large",
    },
    type: {
      type: String,
      default: "converging-spinner",
    },
  },
  data() {
    return {
      myWindow: null,
      token: "",
      modalStepper: 1,
      buttonName: "Unsubscribe",
      isUnsubscribed: true,
      subscriberInfo: {},
      updateInfo: false,
      isLoading: false,
      loader: false,
    };
  },
  computed: {},
  mounted() {
    this.validation();
  },
  methods: {
    scrollToTopHandler() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async validation() {
      this.loader = true;
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Campaign/get-subscriber-info?UId=${this.$route.params.UId}`,
        method: "GET",
      })
        .then((response) => {
          this.loader = false;
          this.subscriberInfo = response.data.data;
          this.modalStepper = this.subscriberInfo?.subscriberStatus == 1 ? 1 : 2;
        })
        .catch((error) => {
          this.loader = false;
        });
    },

    cancel() {
      window.close();
    },

    async updateSubscriberInfo() {
      this.isLoading = true;

      let payload = {
        id: this.subscriberInfo.id,
        subscriberStatus: this.modalStepper == 1 ? false : true,
      };

      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Campaign/update-subscriber-info`,
        method: "PUT",
        data: payload,
      })
        .then((response) => {
          this.isLoading = false;
          this.modalStepper = this.modalStepper == 1 ? 2 : this.modalStepper == 2 ? 3 : 1;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style type="text/css"></style>
